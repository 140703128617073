// // // src/app/auth/services/permissions.service.ts
// // import { Injectable } from '@angular/core';
// // import { HttpClient } from '@angular/common/http';
// // import { Observable, of } from 'rxjs';
// // import { UserRoles } from '../roles.model';

// // @Injectable({
// //   providedIn: 'root'
// // })
// // export class PermissionsService {
// //   private apiUrl = 'YOUR_API_ENDPOINT'; // Replace with your API endpoint
// //   private userRoles: UserRoles;

// //   constructor(private http: HttpClient) { }

// //   fetchPermissions(): Observable<{ Roles: UserRoles }> {
// //     // return this.http.get<{ Roles: UserRoles }>(this.apiUrl);
// //     const roles: UserRoles = {
// //       pages: { Edit: true, View: true },
// //       dashboard: { Edit: true, View: true },
// //       AccessControl: { Edit: true, View: true },
// //       UserManagement: { Edit: true, View: true },
// //       MConnect: { Edit: true, View: true },
// //       ExternalSource: { Edit: true, View: true },
// //       SystemSettings: { Edit: true, View: true },
// //       WhishMoney: { Edit: true, View: true },
// //       SystemHealth: { Edit: true, View: true },
// //       Logs: null
// //     };
// //     console.log('Fetched roles:', roles);
// //     // Simulate an API response using RxJS 'of' function
// //     return of({ Roles: roles });
// //   }

 
// //   setUserRoles(roles: UserRoles) {
// //     this.userRoles = roles;
// //     console.log('User roles set:', this.userRoles);
// //   }

// //   getUserRoles(): UserRoles {
// //     return this.userRoles;
// //   }

// //   canEdit(page: string): boolean {
// //     console.log('Checking edit permission for:', page, this.userRoles[page]?.Edit ?? false);
// //     return this.userRoles[page]?.Edit ?? false;
// //   }
// //   canView(page: string): boolean {
// //     console.log('Checking view permission for:', page, this.userRoles[page]?.View ?? false);
// //     return this.userRoles[page]?.View ?? false;
// //   }
// // }


// import { Injectable } from "@angular/core";
// import { HttpClient } from "@angular/common/http";
// import { Observable } from "rxjs";
// import { environment } from "src/environments/environment";
// import { map } from "rxjs/operators";
// import { ConnectionprofileComponent } from "@modules/dashboard/sections/connection-profile/connectionprofile/connectionprofile.component";
// import { SharedService } from "@core/services/shared.service";

// export interface UserRoles {
//   [key: string]: { Edit: boolean; View: boolean };
// }

// @Injectable({
//   providedIn: "root",
// })
// export class PermissionsService {
//   private userRoles: UserRoles = {};

//   constructor(private http: HttpClient,private sharedService: SharedService) {}

//   fetchPermissions(): Observable<UserRoles> {
//     // Simulate API call with static data for now
//     // ConnectionprofileComponent.SwitchData;
//     console.log('dddd',this.sharedService.getUserInfo());
//     const roles = {
//       Roles: {
//         pages: { Edit: false, View: true },
//         Dashboard: { Edit: true, View: true },
//         AccessControl: { Edit: true, View: true },
//         UserManagement: { Edit: true, View: true },
//         MConnect: { Edit: true, View: true },
//         ExternalSource: { Edit: true, View: true },
//         SystemSettings: { Edit: true, View: true },
//         WhishMoney: { Edit: true, View: true },
//         SystemHealth: { Edit: true, View: true },
//         Logs: { Edit: false, View: true },
//       },
//     };
//     return new Observable((observer) => {
//       observer.next(roles.Roles);
//       observer.complete();
//     });
//   }

//   setUserRoles(roles: UserRoles): void {
//     console.log('e',roles)
//     this.userRoles = roles;
//   }

//   getUserRoles(): UserRoles {
//     return this.userRoles;
//   }




//   canView(page: string): boolean {
//     const permissions = this.userRoles[page];
//     if (!permissions) {
//       console.warn(`No permissions found for page: ${page}`);
//       return false;
//     }
//     return permissions.View;
//   }

//   canEdit(page: string): boolean {
//     const permissions = this.userRoles[page];
//     if (!permissions) {
//       console.warn(`No permissions found for page: ${page}`);
//       return false;
//     }
//     return permissions.Edit;
//   }
// }


import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '@core/services/shared.service';
// import { UserRoles } from '../roles.model';
import { AuthService } from '../auth.service';

export interface PagePermissions {
  Add: boolean;
  Edit: boolean;
  View: boolean;
  Delete: boolean;
  Display: boolean;
}

export interface UserRoles {
  [key: string]: PagePermissions;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  // private userRoles: { [key: string]: PagePermissions } = {};
  private userRoles: UserRoles = {};
  private rolesSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient,private sharedService: SharedService) {
    this.loadRolesFromStorage();
  }

  private loadRolesFromStorage() {
    const storedRoles = localStorage.getItem('userRoles');
    if (storedRoles) {
      try {
        this.userRoles = JSON.parse(storedRoles);
      } catch (e) {
        console.error('Error parsing user roles from localStorage:', e);
        this.userRoles = {};
      }
    }
  }
  fetchPermissions(): Observable<UserRoles> {
    const roles = JSON.parse(localStorage.getItem('userdata'))
    console.log(roles)
    if (roles) {
      try {
        const userData = roles;
        this.userRoles=roles;
        if (userData) {
          const transformedRoles: UserRoles = {};
          for (const [key, value] of Object.entries(userData)) {
              transformedRoles[key] = value as PagePermissions;
            }
          return of(transformedRoles);
        }
      } catch (e) {
        console.error('Error parsing user data from localStorage:', e);
      }
    }
    return of({});
    // const roles = {
    //   Roles: {
    //     pages: { Edit: false, View: true },
    //     Dashboard: { Edit: true, View: true },
    //     AccessControl: { Edit: true, View: true },
    //     UserManagement: { Edit: true, View: true },
    //     MConnect: { Edit: true, View: true },
    //     ExternalSource: { Edit: true, View: true },
    //     SystemSettings: { Edit: true, View: true },
    //     WhishMoney: { Edit: true, View: true },
    //     SystemHealth: { Edit: true, View: true },
    //     Logs: { Edit: false, View: true },
    //     userrole:{ Edit: true, View: true },
    //     topup:{ Edit: false, View: false },

    //   },
    // };
    // return new Observable((observer) => {
    //   observer.next(roles.Roles);
    //   observer.complete();
    // });
  }
  fetchAgentPermissions(): Observable<UserRoles> {
    alert('e')
    const roles = JSON.parse(localStorage.getItem('userdata'));
    // if (roles) {
    //   const transformedRoles: UserRoles = {};
    //   for (const [key, value] of Object.entries(roles)) {
    //     transformedRoles[key] = value as UserRoles[keyof UserRoles];
    //   }
    //   return of(transformedRoles);
    // }
    // return of({});
    if (roles) {
      try {
        const userData = JSON.parse(roles);
        if (userData && userData.Claims) {
          const transformedRoles: UserRoles = {};
          for (const [key, value] of Object.entries(userData.Claims)) {
            transformedRoles[key] = value as UserRoles[keyof UserRoles];
          }
          return of(transformedRoles);
        }
      } catch (e) {
        console.error('Error parsing user data from localStorage:', e);
      }
    }
    return of({});
    // console.log('e',roles)
    // const roles = {
    //   Roles: {
    //     pages: { Edit: false, View: true },
    //     Dashboard: { Edit: true, View: true },
    //     AccessControl: { Edit: true, View: true },
    //     UserManagement: { Edit: true, View: true },
    //     MConnect: { Edit: true, View: true },
    //     ExternalSource: { Edit: true, View: true },
    //     SystemSettings: { Edit: true, View: true },
    //     WhishMoney: { Edit: true, View: true },
    //     SystemHealth: { Edit: true, View: true },
    //     Logs: { Edit: false, View: true },
    //     userrole:{ Edit: true, View: true },
    //     topup:{ Edit: true, View: true },
    //   },
    // };
    // return new Observable((observer) => {
    //   observer.next(roles.Roles);
    //   observer.complete();
    // });
  }
  setUserRoles(roles) {
    this.userRoles = roles;
    localStorage.setItem('userRoles', JSON.stringify(roles));
    // this.rolesSubject.next(roles);
  }

  getUserRoles(): Observable<any> {
    return this.rolesSubject.asObservable();
  }

  canView(page: string): boolean {
    const permissions = this.userRoles[page];
    console.log('1',permissions)
    if (!permissions) {
      console.warn(`No permissions found for page: ${page}`);
      return false;
    }
    return permissions.View;
  }

  canEdit(page: string): boolean {
    const permissions = this.userRoles[page];
    console.log('2',permissions)

    if (!permissions) {
      console.warn(`No permissions found for page: ${page}`);
      return false;
    }
    return permissions.Edit;
  }
  canDelete(page: string): boolean {
    const permissions = this.userRoles[page];
    console.log('2',permissions)

    if (!permissions) {
      console.warn(`No permissions found for page: ${page}`);
      return false;
    }
    return permissions.Delete;
  }
  canAdd(page: string): boolean {
    const permissions = this.userRoles[page];
    console.log('2',permissions)

    if (!permissions) {
      console.warn(`No permissions found for page: ${page}`);
      return false;
    }
    return permissions.Add;
  }

  permissionsLoaded(): boolean {
    return Object.keys(this.userRoles).length > 0;
  }
}
